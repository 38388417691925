export const CovidSvg = () => import('../../components/global/templates/featured-icons/CovidSvg.vue' /* webpackChunkName: "components/covid-svg" */).then(c => wrapFunctional(c.default || c))
export const DeathSvg = () => import('../../components/global/templates/featured-icons/DeathSvg.vue' /* webpackChunkName: "components/death-svg" */).then(c => wrapFunctional(c.default || c))
export const DengueSvg = () => import('../../components/global/templates/featured-icons/DengueSvg.vue' /* webpackChunkName: "components/dengue-svg" */).then(c => wrapFunctional(c.default || c))
export const HexagonSvg = () => import('../../components/global/templates/featured-icons/HexagonSvg.vue' /* webpackChunkName: "components/hexagon-svg" */).then(c => wrapFunctional(c.default || c))
export const HospitalSvg = () => import('../../components/global/templates/featured-icons/HospitalSvg.vue' /* webpackChunkName: "components/hospital-svg" */).then(c => wrapFunctional(c.default || c))
export const MedSvg = () => import('../../components/global/templates/featured-icons/MedSvg.vue' /* webpackChunkName: "components/med-svg" */).then(c => wrapFunctional(c.default || c))
export const TemplateSvg = () => import('../../components/global/templates/featured-icons/TemplateSvg.vue' /* webpackChunkName: "components/template-svg" */).then(c => wrapFunctional(c.default || c))
export const AccordionTemplate = () => import('../../components/global/templates/AccordionTemplate.vue' /* webpackChunkName: "components/accordion-template" */).then(c => wrapFunctional(c.default || c))
export const AlertTemplate = () => import('../../components/global/templates/AlertTemplate.vue' /* webpackChunkName: "components/alert-template" */).then(c => wrapFunctional(c.default || c))
export const BannerTemplate = () => import('../../components/global/templates/BannerTemplate.vue' /* webpackChunkName: "components/banner-template" */).then(c => wrapFunctional(c.default || c))
export const ButtonTemplate = () => import('../../components/global/templates/ButtonTemplate.vue' /* webpackChunkName: "components/button-template" */).then(c => wrapFunctional(c.default || c))
export const CardItemTemplate = () => import('../../components/global/templates/CardItemTemplate.vue' /* webpackChunkName: "components/card-item-template" */).then(c => wrapFunctional(c.default || c))
export const CardTemplate = () => import('../../components/global/templates/CardTemplate.vue' /* webpackChunkName: "components/card-template" */).then(c => wrapFunctional(c.default || c))
export const ContactUsModal = () => import('../../components/global/templates/ContactUsModal.vue' /* webpackChunkName: "components/contact-us-modal" */).then(c => wrapFunctional(c.default || c))
export const DowntimeAlertTemplate = () => import('../../components/global/templates/DowntimeAlertTemplate.vue' /* webpackChunkName: "components/downtime-alert-template" */).then(c => wrapFunctional(c.default || c))
export const FeaturesTemplate = () => import('../../components/global/templates/FeaturesTemplate.vue' /* webpackChunkName: "components/features-template" */).then(c => wrapFunctional(c.default || c))
export const IconFeatureTemplate = () => import('../../components/global/templates/IconFeatureTemplate.vue' /* webpackChunkName: "components/icon-feature-template" */).then(c => wrapFunctional(c.default || c))
export const IconTemplate = () => import('../../components/global/templates/IconTemplate.vue' /* webpackChunkName: "components/icon-template" */).then(c => wrapFunctional(c.default || c))
export const LeaveAlertTemplate = () => import('../../components/global/templates/LeaveAlertTemplate.vue' /* webpackChunkName: "components/leave-alert-template" */).then(c => wrapFunctional(c.default || c))
export const MediumModal = () => import('../../components/global/templates/MediumModal.vue' /* webpackChunkName: "components/medium-modal" */).then(c => wrapFunctional(c.default || c))
export const PackagesTemplate = () => import('../../components/global/templates/PackagesTemplate.vue' /* webpackChunkName: "components/packages-template" */).then(c => wrapFunctional(c.default || c))
export const PetPackagesOld = () => import('../../components/global/templates/PetPackages-old.vue' /* webpackChunkName: "components/pet-packages-old" */).then(c => wrapFunctional(c.default || c))
export const PetPackages = () => import('../../components/global/templates/PetPackages.vue' /* webpackChunkName: "components/pet-packages" */).then(c => wrapFunctional(c.default || c))
export const SliderTemplate = () => import('../../components/global/templates/SliderTemplate.vue' /* webpackChunkName: "components/slider-template" */).then(c => wrapFunctional(c.default || c))
export const SliderTopOfTheFold = () => import('../../components/global/templates/SliderTopOfTheFold.vue' /* webpackChunkName: "components/slider-top-of-the-fold" */).then(c => wrapFunctional(c.default || c))
export const TextTemplate = () => import('../../components/global/templates/TextTemplate.vue' /* webpackChunkName: "components/text-template" */).then(c => wrapFunctional(c.default || c))
export const ValidationTemplate = () => import('../../components/global/templates/ValidationTemplate.vue' /* webpackChunkName: "components/validation-template" */).then(c => wrapFunctional(c.default || c))
export const BackToTop = () => import('../../components/global/BackToTop.vue' /* webpackChunkName: "components/back-to-top" */).then(c => wrapFunctional(c.default || c))
export const Bottombar = () => import('../../components/global/Bottombar.vue' /* webpackChunkName: "components/bottombar" */).then(c => wrapFunctional(c.default || c))
export const BottombarCta = () => import('../../components/global/BottombarCta.vue' /* webpackChunkName: "components/bottombar-cta" */).then(c => wrapFunctional(c.default || c))
export const Breadcrumbs = () => import('../../components/global/Breadcrumbs.vue' /* webpackChunkName: "components/breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const ContentSpaceFiller = () => import('../../components/global/ContentSpaceFiller.vue' /* webpackChunkName: "components/content-space-filler" */).then(c => wrapFunctional(c.default || c))
export const Pagination = () => import('../../components/global/Pagination.vue' /* webpackChunkName: "components/pagination" */).then(c => wrapFunctional(c.default || c))
export const Recommended = () => import('../../components/global/Recommended.vue' /* webpackChunkName: "components/recommended" */).then(c => wrapFunctional(c.default || c))
export const Topbar = () => import('../../components/global/Topbar.vue' /* webpackChunkName: "components/topbar" */).then(c => wrapFunctional(c.default || c))
export const ModalAdsModal = () => import('../../components/global/modal/AdsModal.vue' /* webpackChunkName: "components/modal-ads-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalAnnouncements = () => import('../../components/global/modal/Announcements.vue' /* webpackChunkName: "components/modal-announcements" */).then(c => wrapFunctional(c.default || c))
export const ModalApiLoader = () => import('../../components/global/modal/ApiLoader.vue' /* webpackChunkName: "components/modal-api-loader" */).then(c => wrapFunctional(c.default || c))
export const ModalCatcher = () => import('../../components/global/modal/Catcher.vue' /* webpackChunkName: "components/modal-catcher" */).then(c => wrapFunctional(c.default || c))
export const ModalCovidPopUpModal = () => import('../../components/global/modal/CovidPopUpModal.vue' /* webpackChunkName: "components/modal-covid-pop-up-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalPageLoader = () => import('../../components/global/modal/PageLoader.vue' /* webpackChunkName: "components/modal-page-loader" */).then(c => wrapFunctional(c.default || c))
export const ModalPreContentLoader = () => import('../../components/global/modal/PreContentLoader.vue' /* webpackChunkName: "components/modal-pre-content-loader" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
